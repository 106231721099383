import { Analytics } from '@vercel/analytics/react';
import { SessionProvider } from 'next-auth/react';
import App, { AppProps } from 'next/app';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from 'styled-components';
import {
  GOOGLE_RECAPTCHA_SITE_KEY,
  IS_PRODUCTION,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
} from 'constants/env';
import { IdealistForBusinessProvider } from 'context/idealistForBusiness';
import { GlobalStyles, theme } from 'utils/theme';
import withApollo from 'utils/withApollo';

class MyApp extends App<AppProps> {
  componentDidMount() {
    // Google analytics
    if (IS_PRODUCTION && NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) {
      TagManager.initialize({
        gtmId: NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
      });
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <IdealistForBusinessProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {/* TODO: Add favicon here  */}
          <GoogleReCaptchaProvider
            reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}
            scriptProps={{
              async: false,
              defer: false,
              appendTo: 'head',
              nonce: undefined,
            }}
          >
            <SessionProvider session={pageProps.session}>
              <Component {...pageProps} />
              <Analytics />
            </SessionProvider>
          </GoogleReCaptchaProvider>
        </ThemeProvider>
      </IdealistForBusinessProvider>
    );
  }
}

export default withApollo(MyApp);
