import { ApolloProvider } from '@apollo/client';
import withApollo from 'next-with-apollo';
import React from 'react';
import { createApolloClient } from './apolloClient';

export default withApollo(createApolloClient, {
  render: ({ Page, props }) => {
    const { apollo } = props;
    return (
      <ApolloProvider client={apollo}>
        <Page {...props} />
      </ApolloProvider>
    );
  },
});
