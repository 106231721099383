import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IDEALIST_FOR_BUSINESS_ON_KEY } from 'constants/localStorage';

interface IdealistForBusinessContextProps {
  businessModeOn: boolean;
  switchBusinessMode: () => void;
}

export const IdealistForBusinessContext = React.createContext<IdealistForBusinessContextProps>({
  businessModeOn: false,
  switchBusinessMode: () => null,
});

interface IdealistForBusinessProviderProps {
  children: React.ReactNode;
}

export const IdealistForBusinessProvider = ({ children }: IdealistForBusinessProviderProps) => {
  const [businessModeOn, setBusinessModeOn] = useState(false);
  const switchBusinessMode = useCallback(
    () => setBusinessModeOn(!businessModeOn),
    [businessModeOn],
  );

  useEffect(() => {
    const businessModeOnLocalStorage =
      localStorage.getItem(IDEALIST_FOR_BUSINESS_ON_KEY) === 'true';

    setBusinessModeOn(businessModeOnLocalStorage);
  }, []);

  useEffect(() => {
    localStorage.setItem(IDEALIST_FOR_BUSINESS_ON_KEY, `${businessModeOn}`);
  }, [businessModeOn]);

  const contextValue = useMemo(() => {
    return {
      businessModeOn,
      switchBusinessMode,
    };
  }, [businessModeOn, switchBusinessMode]);

  return (
    <IdealistForBusinessContext.Provider value={contextValue}>
      {children}
    </IdealistForBusinessContext.Provider>
  );
};
