export const NODE_ENV = process.env.NODE_ENV || 'unknown';
export const APP_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV || 'local';
export const IS_PRODUCTION = NODE_ENV === 'production';

export const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || undefined;

export const API_KEY_QUERY_KEY = 'API_KEY';
export const API_ACCESS_KEY = process.env.API_ACCESS_KEY || '';

export const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || '';

export const NEXT_PUBLIC_APP_URL = process.env.NEXT_PUBLIC_APP_URL || 'http://localhost:3000';

export const APP_URL = process.env.NEXT_PUBLIC_VERCEL_URL
  ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
  : NEXT_PUBLIC_APP_URL;

export const ADMIN_EMAIL_ADDRESS =
  process.env.NEXT_PUBLIC_ADMIN_EMAIL_ADDRESS || 'antonmihai58@gmail.com';

// Authentication
export const NEXT_AUTH_SECRET = process.env.NEXTAUTH_SECRET || 'this should be secret';
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID || '';
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET || '';

// Sendgrid
export const SENDGRID_FROM = process.env.SENDGRID_FROM || '';
export const SENDGRID_API_KEY = process.env.SENDGRID_API_KEY || '';

// Unsplash
export const UNSPLASH_ACCESS_KEY =
  process.env.UNSPLASH_ACCESS_KEY || '2h9KL5NkpEub99QobWPAxEKP_2oby69cnlD2zo7xLhg';

// Google recaptcha
export const GOOGLE_RECAPTCHA_SITE_KEY =
  process.env.GOOGLE_RECAPTCHA_SITE_KEY || '6LcvU7IkAAAAALOw9J2dVbfEdDW1rokEmBNlK-5J';
export const GOOGLE_RECAPTCHA_SECRET_KEY = process.env.GOOGLE_RECAPTCHA_SECRET_KEY || '';

// Google maps
export const NEXT_PUBLIC_GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '';
