import { NEXT_PUBLIC_APP_URL } from './env';

// internal
export const HOME_URL = '/';
export const BUSINESS_HOME_URL = '/business';
export const BLOG_URL = '/blog';
export const API_URL = '/api';

export const RESUME_URL = '/resume';
export const SIGNIN_URL = '/signin';
export const makeSigninUrl = () => `${NEXT_PUBLIC_APP_URL}${SIGNIN_URL}`;
export const AUTH_ERROR_URL = '/auth/error';
export const REFERRAL_REQUIRED_URL = '/referral/required';
export const VERIFY_EMAIL_URL = '/profile/verify';
export const makeVerifyUrl = (token: string) =>
  `${NEXT_PUBLIC_APP_URL}${VERIFY_EMAIL_URL}?token=${token}`;
export const PASSWORD_RESET_URL = '/profile/password-reset';
export const makePasswordResetUrl = (token: string) =>
  `${NEXT_PUBLIC_APP_URL}${PASSWORD_RESET_URL}?token=${token}`;

export const SUBSCRIPTION_URL = '/business/subscription';
export const makeSubscriptionUrl = (type: 'monthly' | 'yearly' | 'corporate') =>
  `${SUBSCRIPTION_URL}?type=${type}`;

// internal/private
export const PROFILE_URL = '/profile';
export const PROFILE_COMPLETION_URL = '/profile/complete';
export const FEED_URL = '/feed';
export const EDIT_IDEALIST_URL = (id: string) => `/idealist/${id}/edit`;
export const PUBLIC_IDEALIST_URL = (id: string) => `${NEXT_PUBLIC_APP_URL}/idealist/${id}/public`;
export const IDEALIST_URL = (id: string) => `/idealist/${id}`;
export const USER_PROFILE_URL = (username: string) => `/${username}`;
export const EXPLORE_URL = '/explore';
export const SIGNOUT_URL = '/logout';
export const GRAPHQL_API_URL = '/api/graphql';

// external

// url functions
export const makePostUrl = (id: string, path?: string) => `${path || BLOG_URL}/${id}`;

// external URL IDs - useful for google analytics tracking
